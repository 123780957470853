import { render, staticRenderFns } from "./SCardToggle.vue?vue&type=template&id=76c37a51&scoped=true&lang=pug"
import script from "./SCardToggle.vue?vue&type=script&setup=true&lang=ts"
export * from "./SCardToggle.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./SCardToggle.vue?vue&type=style&index=0&id=76c37a51&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "76c37a51",
  null
  
)

export default component.exports