<script setup lang="ts">import { computed as _computed } from 'vue';

import { type QualityItem } from '@/modules/catalog/asset/data-quality/data-quality';
import SChipBasic from '@/components/SChipBasic.vue';
import { type SiffletColors } from '@/plugins/theme';
import i18n from '@/i18n';

const props = defineProps({
  dataQuality: null,
  noInfoText: { default: '' }
});

const state = _computed(() => {
  if (props.dataQuality?.failed) {
    return 'danger';
  }
  if (props.dataQuality?.needsAttention) {
    return 'warning';
  }
  if (props.dataQuality?.passed) {
    return 'success';
  }
  return 'no-info';
});

const config = _computed(() => {
  switch (state.value) {
    case 'no-info':
      return {
        color: 'textSecondary' as SiffletColors,
        text: props.noInfoText,
      };
    case 'success':
      return {
        color: 'textSuccess' as SiffletColors,
        icon: 'icon-check-circle-fill',
        text: i18n.t('assets.data_quality_chip.success'),
      };
    case 'warning':
      return {
        color: 'iconWarning' as SiffletColors,
        icon: 'icon-warning-fill',
        text: i18n.t('assets.data_quality_chip.warning'),
        textColor: 'textWarning' as SiffletColors,
      };
    case 'danger':
      return {
        color: 'textDanger' as SiffletColors,
        icon: 'icon-error-circle-fill',
        text: i18n.t('assets.data_quality_chip.danger'),
      };
    default:
      break;
  }
  return null;
});
</script>

<template lang="pug">
SChipBasic( v-if="config" size="small" :icon="config.icon" :text="config.text" :color="config.color" :text-color="config.textColor" )
</template>
