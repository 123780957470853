import { MAIN_ROUTES, SUB_ROUTES } from '@/router/components';
import sources from '@/router/sources';
import { BackgroundColors } from '@/@types/types';

export default {
  path: '/integrations',
  name: 'integrations',
  redirect: { name: 'sources' },
  meta: {
    requiresAuth: true,
    breadCrumb: 'app.navigation-drawer.integrations',
  },
  component: MAIN_ROUTES.INTEGRATIONS,
  children: [
    sources,
    {
      path: 'credentials',
      name: 'integrations.credentials',
      meta: {
        requiresAuth: true,
        breadCrumb: 'app.navigation-drawer.credentials',
        background: BackgroundColors.PRIMARY,
        appBar: BackgroundColors.PRIMARY,
      },
      component: SUB_ROUTES.INTEGRATION_CREDENTIALS,
    },
  ],
};
