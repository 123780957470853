/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { FilterConditionDto } from './FilterConditionDto';

export type SourceFilterConditionDto = (FilterConditionDto & {
    operator?: SourceFilterConditionDto.operator;
    sourceIds?: Array<string>;
} & {
    operator: SourceFilterConditionDto.operator;
    sourceIds: Array<string>;
});

export namespace SourceFilterConditionDto {

    export enum operator {
        IS = 'IS',
        IS_NOT = 'IS_NOT',
    }


}
