/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { FilterConditionDto } from './FilterConditionDto';

export type DatasetNameFilterConditionDto = (FilterConditionDto & {
    operator?: DatasetNameFilterConditionDto.operator;
    pattern?: string;
} & {
    operator: DatasetNameFilterConditionDto.operator;
    pattern: string;
});

export namespace DatasetNameFilterConditionDto {

    export enum operator {
        STARTS_WITH = 'STARTS_WITH',
        NOT_STARTS_WITH = 'NOT_STARTS_WITH',
        CONTAINS = 'CONTAINS',
        NOT_CONTAINS = 'NOT_CONTAINS',
    }


}
