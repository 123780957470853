/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type FilterConditionDto = {
    type: FilterConditionDto.type;
};

export namespace FilterConditionDto {

    export enum type {
        SOURCE = 'SOURCE',
        TAG = 'TAG',
        DATASET_NAME = 'DATASET_NAME',
    }


}
