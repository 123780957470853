<script setup lang="ts">
import __MACROS_useVModel from "/vue-macros/define-models/use-vmodel";
defineEmits(["input"]);
interface CardToggle {
  type: any;
  icon?: string;
  text: string;
  description?: string;
  disabled?: boolean;
}

interface SCardToggleProps {
  items?: CardToggle[];
}

defineProps({
  items: { default: () => [] },
  value: null
});

const { value: model } = __MACROS_useVModel(["value", undefined, "input"]);

</script>

<template lang="pug">
.s-card-toggle
  v-item-group.d-flex( v-model="model" mandatory )
    v-item(
      v-for="item in items"
      v-slot="{ active, toggle }"
      :key="item.type"
      :value="item.type" )
      v-card.pa-3(
        class="s-card-toggle--card"
        :class="{ selected: active }"
        @click="toggle"
        outlined
        :data-cy="item.type"
        :disabled="item.disabled")
          .checked-icon
            v-icon( v-if="active" color="iconInfo" ) icon-check-circle-fill
          .d-flex.align-center.mb-1
            v-icon( size="20" ).mr-1 {{ item.icon }}
            b.body-2.lh-1 {{ item.text }}
          .caption.grey--text {{ item.description }}
</template>

<style lang="scss" scoped>
.s-card-toggle {
  .s-card-toggle--card {
    cursor: pointer;
    position: relative;
    flex: 1 1 0;

    &.selected {
      background: var(--v-bgAccentPrimary-base);
      border: 1px solid var(--v-borderAccent-base) !important;

      &+.s-card-toggle--card {
        border-left-color: transparent !important;
      }
    }

    &:not(:last-child) {
      border-right-color: transparent;
    }

    .checked-icon {
      position: absolute;
      top: 12px;
      right: 12px;
    }

    &:first-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &:last-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    &:not(:first-child):not(:last-child) {
      border-radius: 0;
    }

    &:only-child {
      border-radius: 4px;
    }
  }
}
</style>
