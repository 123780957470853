/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ParameterizedQueryDto } from './ParameterizedQueryDto';
import type { RuleRunDiscrepancy } from './RuleRunDiscrepancy';

export type RuleRunDetailsByGroup = {
    debug?: ParameterizedQueryDto;
    discrepancies?: Array<RuleRunDiscrepancy>;
    encryptedGroupValue?: string;
    executionData?: Record<string, Record<string, any>>;
    groupKey?: Array<string>;
    groupValue?: string;
    hashedGroupValue?: string;
    result?: string;
    status?: RuleRunDetailsByGroup.status;
};

export namespace RuleRunDetailsByGroup {

    export enum status {
        PENDING = 'PENDING',
        RUNNING = 'RUNNING',
        SUCCESS = 'SUCCESS',
        REQUIRES_YOUR_ATTENTION = 'REQUIRES_YOUR_ATTENTION',
        TECHNICAL_ERROR = 'TECHNICAL_ERROR',
        FAILED = 'FAILED',
    }


}
