<template lang="pug">
#monitors-sort.d-flex( data-cy="monitors-sort" style="position: relative;" )
  v-select(
    :items="sortConfiguration"
    v-model="sortString"
    style="width: 250px;"
    attach="#monitors-sort"
    :placeholder="$t('common.words.sort_by')"
    data-cy="monitors-sort-select"
    :menu-props="{ bottom: true, offsetY: true }"
    hide-details dense outlined )
  v-btn.ml-2.single-icon(
    @click="sortHandler"
    color="secondary"
    data-cy="monitors-sort-button"
    class="custom-secondary icon-only"
    text outlined)
    v-icon {{ icon }}

  </template>

  <script lang="ts">
  import { Vue, Component } from 'vue-property-decorator';
  import monitorModule from '@/store/modules/monitors';
  import { getModule } from 'vuex-module-decorators';
  import store from '@/store';
  import i18n from '@/i18n';
  import { computed } from 'vue';

  let monitor :monitorModule;

  const sortConfiguration = computed(() => [
    { value: 'lastRunDate', text: i18n.t('monitors.sort_configuration.last_run_date') },
    { value: 'lastRunStatus', text: i18n.t('monitors.sort_configuration.last_run_status') },
    { value: 'criticality', text: i18n.t('monitors.sort_configuration.severity') },
    { value: 'name', text: i18n.t('monitors.sort_configuration.name') },
    { value: 'createdDate', text: i18n.t('monitors.sort_configuration.created_date') },
  ]);

  @Component
  export default class MonitorsSort extends Vue {
    get sortString() {
      return monitor.sorting.sort[0].split(',')[0];
    }

    set sortString(sortName) {
      monitor.setValues({ sort: [`${sortName},${this.sortDirection}`] });
    }

    get sortDirection() {
      return monitor.sorting.sort[0].split(',')[1];
    }

    set sortDirection(sortDirection) {
      monitor.setValues({ sort: [`${this.sortString},${sortDirection}`] });
    }

    get sortConfiguration() {
      return sortConfiguration.value;
    }

    get icon() {
      return this.sortDirection === 'ASC' ? 'icon-sort-text-az' : 'icon-sort-text-za';
    }

    sortHandler() {
      const value = this.sortDirection === 'ASC' ? 'DESC' : 'ASC';
      monitor.setValues({ sort: [`${this.sortString},${value}`] });
    }

    beforeCreate() {
      monitor = getModule(monitorModule, store);
    }
  }

  </script>
