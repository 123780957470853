/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { RuleRunDebug } from './RuleRunDebug';
import type { RuleRunDetails } from './RuleRunDetails';

export type RuleRunPatchDto = {
    dataScanDetails?: string | null;
    debug?: RuleRunDebug;
    details?: RuleRunDetails;
    endDate?: number | null;
    result?: string | null;
    startDate?: number | null;
    status?: RuleRunPatchDto.status | null;
};

export namespace RuleRunPatchDto {

    export enum status {
        PENDING = 'PENDING',
        RUNNING = 'RUNNING',
        SUCCESS = 'SUCCESS',
        REQUIRES_YOUR_ATTENTION = 'REQUIRES_YOUR_ATTENTION',
        TECHNICAL_ERROR = 'TECHNICAL_ERROR',
        FAILED = 'FAILED',
    }


}
