<template lang="pug">
v-breadcrumbs(
  class="flex-nowrap flex-grow-1 flex-shrink-1"
  :items='breadcrumbs'
  style="width: 100px;" )
  template(v-slot:item='{ item }')
    v-breadcrumbs-item(
      class="item"
      :exact="!item.exact"
      :replace="item.replace"
      :to='item.to')
      span.text-h6( class="text-truncate font-weight-medium" :class="color(item.exact)" ) {{ $t(item.text) }}
</template>

<script lang="ts">
import { Vue, Component, Watch } from 'vue-property-decorator';
import breadcrumbModule from '@/store/modules/breadcrumb';
import { getModule } from 'vuex-module-decorators';
import store from '@/store';
import { Route } from 'vue-router';

let breadcrumb: breadcrumbModule;

@Component
export default class Breadcrumb extends Vue {
  @Watch('$route', { immediate: true, deep: true })
  onUrlChange(to: Route, from: Route) {
    breadcrumb.createBreadcrumbs({ to, from });
  }

  get breadcrumbs() {
    return breadcrumb.getBreadcrumbs;
  }

  beforeCreate() {
    breadcrumb = getModule(breadcrumbModule, store);
  }

  color(exact: boolean) {
    // eslint-disable-next-line no-nested-ternary
    return this.$vuetify.theme.dark
      ? 'white--text'
      : exact
        ? 'grey--text text--darken-4'
        : 'grey--text';
  }
}
</script>

<style lang="scss">
.v-application ul {
  padding-left: 2px;
}

.v-breadcrumbs {
  > li.item:not(:first-child) {
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;

  }
  &__item {
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
  }
}
</style>
