import { BackgroundColors } from '@/@types/types';
import {
  MAIN_ROUTES,
} from '@/router/components';
import { UserUiDto } from '@/api';

export default {
  path: 'preferences',
  name: 'settings.preferences',
  meta: {
    requiresAuth: true,
    roles: [UserUiDto.role.ADMIN],
    breadCrumb: 'app.navigation-drawer.preferences',
    background: BackgroundColors.SECONDARY,
  },
  component: MAIN_ROUTES.PREFERENCES,
};
