/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type RuleRunDiscrepancy = {
    date?: string;
    'y'?: number;
    ymax?: number;
    ymin?: number;
    yp?: number;
    ypast?: number;
    ytype?: RuleRunDiscrepancy.ytype;
};

export namespace RuleRunDiscrepancy {

    export enum ytype {
        ANOMALY = 'ANOMALY',
        FIXED_AUTO_QUALIFIED = 'FIXED_AUTO_QUALIFIED',
    }


}
