/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ContinuousDistribution } from './ContinuousDistribution';
import type { DiscreteDistribution } from './DiscreteDistribution';
import type { InterlinkedMetrics } from './InterlinkedMetrics';
import type { TimeSeries } from './TimeSeries';

export type ButterflyDebug = {
    currentDistributionContinuous?: ContinuousDistribution;
    currentDistributionDiscrete?: DiscreteDistribution;
    distributionType?: ButterflyDebug.distributionType | null;
    formerDistributionContinuous?: ContinuousDistribution;
    formerDistributionDiscrete?: DiscreteDistribution;
    hasGroupBy: boolean;
    interlinkedMetricsGraph?: InterlinkedMetrics;
    modelType?: string | null;
    parametersHash?: string | null;
    timeSeriesGraph?: TimeSeries;
    timezone?: string | null;
};

export namespace ButterflyDebug {

    export enum distributionType {
        DISCRETE = 'DISCRETE',
        CONTINUOUS = 'CONTINUOUS',
        OTHER = 'OTHER',
    }


}
