<script setup lang="ts">import { ref as _ref, toRef as _toRef, computed as _computed } from 'vue';

import {
  AssetDto,
  AssetHeaderDto,
  AssetService, type AuthorizedAssetHeaderDto,
  DagAssetOverview,
  DashboardAssetOverview,
  DatasetAssetOverview,
  type DeclaredAssetOverview,
  TransformationAssetOverview, type UnauthorizedAssetHeaderDto,
} from '@/api';
import eventBus, { EventType } from '@/utils/eventBus';
import DeclaredAssetOverviewDetail from '@/components/asset/overview/DeclaredAssetOverviewDetail.vue';
import { onMounted, watch, onBeforeUnmount } from 'vue';
import useAsset from '@/modules/catalog/Asset';
import DataQuality from '@/modules/catalog/asset/data-quality/DataQuality.vue';
import useDataQuality from '@/modules/catalog/asset/data-quality/data-quality';

interface AssetTabOverviewProps {
  urn: AssetDto['urn'];
}

const props = defineProps({
  urn: null
});

let overviewData = _ref<DagAssetOverview | DashboardAssetOverview | DatasetAssetOverview | TransformationAssetOverview | DeclaredAssetOverview | null>(null);
let assetData = _ref<AuthorizedAssetHeaderDto | UnauthorizedAssetHeaderDto | null>(null);

const __$temp_1 = (useAsset()),
  shouldFetchDetails = _toRef(__$temp_1, 'shouldFetchDetails'),
  setShouldFetchDetails = _toRef(__$temp_1, 'setShouldFetchDetails');;
const __$temp_2 = (useDataQuality()),
  setUrn = _toRef(__$temp_2, 'setUrn');;

const schema = _computed(() => {
  const datasetAssetOverview = overviewData.value as DatasetAssetOverview;
  return datasetAssetOverview?.schema;
});

const transformation = _computed(() => {
  const transformationAssetOverview = overviewData.value as TransformationAssetOverview;
  return transformationAssetOverview?.transformationAssetDto;
});

const details = _computed(() => overviewData.value?.details);
const hasSchema = _computed(() => schema.value !== undefined);
const hasTransformation = _computed(() => transformation.value !== undefined);

const mdLeftCols = _computed(() => {
  if (hasSchema.value) {
    return 8;
  }
  if (hasTransformation.value) {
    return 6;
  }
  return 12;
});

const mdRightCols = _computed(() => {
  if (hasSchema.value) {
    return 4;
  }
  if (hasTransformation.value) {
    return 6;
  }
  return 0;
});

const displayDeclaredAssetOverview = _computed(() => assetData.value?.entityType === AssetHeaderDto.entityType.DECLARED_ASSET);
const hasDataQuality = _computed(() => assetData.value?.displayedType === AssetHeaderDto.displayedType.VIEW
|| assetData.value?.displayedType === AssetHeaderDto.displayedType.EXTERNAL_TABLE
|| assetData.value?.displayedType === AssetHeaderDto.displayedType.TABLE);

const getAssetOverview = async () => {
  overviewData.value = await AssetService.getAssetOverviewByUrn({ urn: props.urn });
};

const getAsset = async () => {
  assetData.value = await AssetService.getAssetHeaderByUrn({ urn: props.urn });
};

const updateHandler = () => {
  getAssetOverview();
};

onMounted(() => {
  getAssetOverview();
  getAsset();
  setUrn.value(props.urn);
  eventBus.$on(EventType.REFRESH_METADATA, updateHandler);
});

onBeforeUnmount(() => {
  eventBus.$off(EventType.REFRESH_METADATA, updateHandler);
});

watch(() => props.urn, () => {
  getAssetOverview();
  getAsset();
  setUrn.value(props.urn);
});

watch(() => shouldFetchDetails.value, () => {
  if (shouldFetchDetails.value) {
    getAssetOverview();
    getAsset();
    setShouldFetchDetails.value(false);
  }
});
</script>

<template lang="pug">
v-container( fluid data-cy="catalog-asset-overview" )
  v-row
    v-col.pt-6.pb-0( v-if="hasDataQuality" cols="12" )
      DataQuality

    v-col( cols="12" :md="mdLeftCols" )
      AssetOverviewDetail( v-if="!displayDeclaredAssetOverview" :overview-data="overviewData" :urn="urn" @update="updateHandler" :asset-data="assetData" )
      DeclaredAssetOverviewDetail( v-if="displayDeclaredAssetOverview" :overview-data="overviewData" :urn="urn" :source-id="details?.datasourceId")

    v-col( v-if="hasSchema" cols="12" :md="mdRightCols" )
      AssetOverviewSchema.columns( :schema="schema" :urn="urn")

    v-col( v-if="hasTransformation" cols="12" :md="mdRightCols" )
      AssetOverviewTransformation( :transformation="transformation" :urn="urn")
</template>

<style lang="scss" scoped>
.columns {
  max-height: 1000px;
  overflow: scroll;
}
</style>
