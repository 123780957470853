<template lang="pug">
v-textarea(
    v-bind="$attrs"
    v-on="$listeners"
    :rules="commentRules"
    :counter='counter'
    data-cy="comment"
    no-resize
    dense outlined)

</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator';
import { Inputs } from '@/@types/types';

@Component
export default class InputComment extends Vue {
  counter = Inputs.INPUT_HIGH_CHARS;

  commentRules = [
    (v: string) => {
      if (v) return v.length <= this.counter || `Max ${this.counter} characters`;
      return true;
    },
  ];
}
</script>
