/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { FilterConditionDto } from './FilterConditionDto';

export type TagFilterConditionDto = (FilterConditionDto & {
    operator?: TagFilterConditionDto.operator;
    tagIds?: Array<string>;
} & {
    operator: TagFilterConditionDto.operator;
    tagIds: Array<string>;
});

export namespace TagFilterConditionDto {

    export enum operator {
        IS = 'IS',
        IS_NOT = 'IS_NOT',
    }


}
