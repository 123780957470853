/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type SupportedDatasourcesDto = {
    supportedMonitors?: Array<'COMPLETENESS' | 'DUPLICATES' | 'FRESHNESS' | 'SCHEMA_CHANGE' | 'METADATA_FRESHNESS' | 'NUMERICAL_TRANSFORMATION' | 'INTERLINKED_METRICS' | 'CUSTOM_METRICS' | 'DISTRIBUTION' | 'UNIQUE' | 'REFERENTIAL_INTEGRITY' | 'NOT_IN_LIST' | 'COUNT_DUPLICATE' | 'COUNT_NULL_ROWS' | 'RANGE' | 'IS_EMAIL' | 'IS_PHONE_NUMBER' | 'IS_UUID' | 'MATCHES_REGEX' | 'NO_CODE' | 'SQL' | 'DBT' | '__OBSOLETE__COMPLETENESS_STATIC' | '__OBSOLETE__STATIC_COUNT_DUPLICATE' | '__OBSOLETE__DUPLICATE' | '__OBSOLETE__NULL' | '__OBSOLETE__PERCENTAGE_NULL_STATIC' | '__OBSOLETE__AVERAGE' | '__OBSOLETE__COUNT' | '__OBSOLETE__QUANTILE' | '__OBSOLETE__SUM' | '__OBSOLETE__STANDARD_DEVIATION' | '__OBSOLETE__VARIANCE' | '__OBSOLETE__PERCENTAGE_NULL_ROWS' | '__OBSOLETE__PERCENTAGE_DUPLICATES'>;
    type?: SupportedDatasourcesDto.type;
};

export namespace SupportedDatasourcesDto {

    export enum type {
        ATHENA = 'ATHENA',
        BIGQUERY = 'BIGQUERY',
        REDSHIFT = 'REDSHIFT',
        SNOWFLAKE = 'SNOWFLAKE',
        DATABRICKS = 'DATABRICKS',
        MSSQL = 'MSSQL',
        MYSQL = 'MYSQL',
        POSTGRES = 'POSTGRES',
        ORACLE = 'ORACLE',
        SYNAPSE = 'SYNAPSE',
        POWER_BI = 'POWER_BI',
        LOOKER = 'LOOKER',
        TABLEAU = 'TABLEAU',
        QUICKSIGHT = 'QUICKSIGHT',
        MICROSTRATEGY = 'MICROSTRATEGY',
        QLIK = 'QLIK',
        AIRFLOW = 'AIRFLOW',
        MWAA = 'MWAA',
        COMPOSER = 'COMPOSER',
        DBT = 'DBT',
        DBTCLOUD = 'DBTCLOUD',
        FIVETRAN = 'FIVETRAN',
        DECLARATIVE = 'DECLARATIVE',
        _UNKNOWN_ = '_UNKNOWN_',
    }


}
