<script setup lang="ts">import { computed as _computed } from 'vue';

import MonitorIcon from '@/modules/monitors/monitor/common/MonitorIcon.vue';
import { type QualityItem } from '@/modules/catalog/asset/data-quality/data-quality';
import DataQualityChip from '@/modules/catalog/asset/data-quality/DataQualityChip.vue';
import DataQualityBar from '@/modules/catalog/asset/data-quality/DataQualityBar.vue';
import EmptyIcon from '@/modules/monitors/monitor/common/EmptyIcon.vue';
import MonitorTextIcon from '@/modules/monitors/monitor/common/MonitorTextIcon.vue';

interface DataQualityCardProps {
  type: 'single' | 'percentage';
  title: string;
  dataQuality: QualityItem;
}

const props = defineProps({
  type: { default: 'single' },
  title: { default: '' },
  dataQuality: { default: null }
});

const isEmpty = _computed(() => !props.dataQuality);
const isSingle = _computed(() => props.type === 'single');
const isPercentage = _computed(() => props.type === 'percentage');
const qualityComponent = isSingle.value ? DataQualityChip : DataQualityBar;
const quantity = _computed(() => props.dataQuality?.total);

</script>

<template lang="pug">
.d-flex
  .d-flex
    EmptyIcon( v-if="isEmpty" )
    MonitorIcon( v-else-if="isSingle" )
    MonitorTextIcon( v-else-if="isPercentage" :text="quantity" )
  .d-flex.flex-column.ml-2
    span.text-no-wrap {{ title }}
    component( :is="qualityComponent" :data-quality="props.dataQuality" no-info-text="--" )
</template>
