<script setup lang="ts">import { computed as _computed } from 'vue';

import __MACROS_useVModel from "/vue-macros/define-models/use-vmodel";
defineEmits(["input"]);
import { useSlots } from 'vue';

const slots = useSlots();

const { value } = __MACROS_useVModel(["value", undefined, "input"]);

interface SCardProps {
  /**
   * Card title
   */
   title: string,
  /**
   * Card subtitle
   */
  subtitle?: string,
}
const props = defineProps({
  title: { default: '' },
  subtitle: { default: '' },
  value: { type: Boolean }
});

const hasTitleContent = _computed(() => !!props.title || !!props.subtitle);

</script>

<template lang="pug">
v-card.sswitch_accordion.pa-0( v-bind="$attrs" )
  .sswitch_accordion-header.d-flex.justify-space-between.align-center( v-if="hasTitleContent" )
    .d-flex
      div
        v-card-title.font-weight-medium.pa-0.ma-0.pr-2.textPrimary--text.body-2( v-if="title" ) {{ title }}
        v-card-subtitle.caption.ma-0.pa-0.pr-2.textSecondary--text( v-if="subtitle" ) {{ subtitle }}
    v-switch.mt-0( v-model="value" hide-details inset data-cy="monitor-where-switch" )
  v-card-text.sswitch_accordion-content.pa-0.textPrimary--text(v-if="slots.default")
    .pt-4( v-if="value" )
      slot

</template>

<style lang="scss">
.sswitch_accordion {
  background-color: transparent !important;
}
</style>
