import {
  MAIN_ROUTES,
  SUB_ROUTES,
} from '@/router/components';
import { BackgroundColors } from '@/@types/types';

export default {
  path: '/business-glossary',
  meta: {
    requiresAuth: true,
    breadCrumb: 'app.navigation-drawer.business-glossary',
    disabled: false,
  },
  component: MAIN_ROUTES.BUSINESS_GLOSSARY,
  children: [
    {
      path: '/',
      name: 'business-glossary',
      meta: {
        requiresAuth: true,
        hideBreadCrumb: true,
        breadCrumb: 'app.navigation-drawer.business-glossary',
        background: BackgroundColors.SECONDARY,
        appBar: BackgroundColors.PRIMARY,
      },
      component: SUB_ROUTES.BUSINESS_GLOSSARY,
    },
  ],
};
