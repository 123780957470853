import {
  MAIN_ROUTES,
  SUB_ROUTES,
} from '@/router/components';
import { BackgroundColors } from '@/@types/types';

export default {
  path: '/dashboard',
  meta: {
    requiresAuth: true,
    breadCrumb: 'app.navigation-drawer.dashboard',
    disabled: false,
  },
  component: MAIN_ROUTES.DASHBOARD,
  children: [{
    path: '/',
    name: 'dashboard',
    meta: {
      requiresAuth: true,
      hideBreadCrumb: true,
      breadCrumb: 'app.navigation-drawer.dashboard',
      disabled: false,
      background: BackgroundColors.SECONDARY,
      appBar: BackgroundColors.SECONDARY,
    },
    component: SUB_ROUTES.DASHBOARD,
  },
  ],
};
