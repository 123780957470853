import {
  MAIN_ROUTES,
  SUB_ROUTES,
} from '@/router/components';
import { BackgroundColors } from '@/@types/types';

export default {
  path: '/incidents',
  meta: {
    requiresAuth: true,
    breadCrumb: 'app.navigation-drawer.incidents',
  },
  component: MAIN_ROUTES.INCIDENTS,
  children: [{
    path: '',
    name: 'incidents',
    meta: {
      requiresAuth: true,
      hideBreadCrumb: true,
      breadCrumb: 'app.navigation-drawer.incidents',
      background: BackgroundColors.SECONDARY,
      appBar: BackgroundColors.PRIMARY,
    },
    component: SUB_ROUTES.INCIDENTS,
  },
  {
    path: 'issue/:issue',
    name: 'incidents.incident',
    meta: {
      requiresAuth: true,
      breadCrumb: 'Incident Overview',
      breadCrumbFromParamName: true,
      params: ['issue', 'name'],
    },
    props: true,
    component: SUB_ROUTES.INCIDENT,
    children: [{
      path: 'overview',
      name: 'incidents.incident.incident_overview',
      props: true,
      meta: {
        requiresAuth: true,
        hideBreadCrumb: true,
        breadCrumb: 'Issue Overview',
        background: BackgroundColors.SECONDARY,
        appBar: BackgroundColors.PRIMARY,
      },
      component: SUB_ROUTES.INCIDENT_OVERVIEW,
    }, {
      path: 'lineage',
      name: 'incidents.incident.incident_lineage',
      props: true,
      meta: {
        requiresAuth: true,
        hideBreadCrumb: true,
        breadCrumb: 'Issue Lineage',
        background: BackgroundColors.SECONDARY,
        appBar: BackgroundColors.PRIMARY,
      },
      component: SUB_ROUTES.INCIDENT_LINEAGE,
    },
    ],
  }],
};
