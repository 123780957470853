/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type DatasourceHierarchyNodeDto = {
    datasourceId?: string;
    monitoredTemplates?: Array<'COMPLETENESS' | 'DUPLICATES' | 'FRESHNESS' | 'SCHEMA_CHANGE' | 'METADATA_FRESHNESS' | 'NUMERICAL_TRANSFORMATION' | 'INTERLINKED_METRICS' | 'CUSTOM_METRICS' | 'DISTRIBUTION' | 'UNIQUE' | 'REFERENTIAL_INTEGRITY' | 'NOT_IN_LIST' | 'COUNT_DUPLICATE' | 'COUNT_NULL_ROWS' | 'RANGE' | 'IS_EMAIL' | 'IS_PHONE_NUMBER' | 'IS_UUID' | 'MATCHES_REGEX' | 'NO_CODE' | 'SQL' | 'DBT' | '__OBSOLETE__COMPLETENESS_STATIC' | '__OBSOLETE__STATIC_COUNT_DUPLICATE' | '__OBSOLETE__DUPLICATE' | '__OBSOLETE__NULL' | '__OBSOLETE__PERCENTAGE_NULL_STATIC' | '__OBSOLETE__AVERAGE' | '__OBSOLETE__COUNT' | '__OBSOLETE__QUANTILE' | '__OBSOLETE__SUM' | '__OBSOLETE__STANDARD_DEVIATION' | '__OBSOLETE__VARIANCE' | '__OBSOLETE__PERCENTAGE_NULL_ROWS' | '__OBSOLETE__PERCENTAGE_DUPLICATES'>;
    name?: string;
    type?: DatasourceHierarchyNodeDto.type;
};

export namespace DatasourceHierarchyNodeDto {

    export enum type {
        SOURCE = 'SOURCE',
        AUTHORITY = 'AUTHORITY',
        DATABASE = 'DATABASE',
        SCHEMA = 'SCHEMA',
    }


}
