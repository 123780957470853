/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PublicCalendarCreateDto } from '../models/PublicCalendarCreateDto';
import type { PublicCalendarGetDto } from '../models/PublicCalendarGetDto';
import type { PublicCalendarUpdateDto } from '../models/PublicCalendarUpdateDto';
import type { PublicPageDtoPublicCalendarGetDto } from '../models/PublicPageDtoPublicCalendarGetDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CalendarsService {

    /**
     * Get list of calendars
     * @returns PublicPageDtoPublicCalendarGetDto Calendars retrieved
     * @throws ApiError
     */
    public static publicGetCalendars(): CancelablePromise<PublicPageDtoPublicCalendarGetDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/calendars',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Create a calendar
     * @returns PublicCalendarGetDto Calendar created
     * @throws ApiError
     */
    public static publicCreateCalendar({
        requestBody,
    }: {
        requestBody: PublicCalendarCreateDto,
    }): CancelablePromise<PublicCalendarGetDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/calendars',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Delete a calendar
     * @returns void
     * @throws ApiError
     */
    public static publicDeleteCalendar({
        id,
    }: {
        id: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/calendars/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get a calendar by id
     * @returns PublicCalendarGetDto Calendar retrieved
     * @throws ApiError
     */
    public static publicGetCalendar({
        id,
    }: {
        id: string,
    }): CancelablePromise<PublicCalendarGetDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/calendars/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Update a calendar
     * @returns PublicCalendarGetDto Calendar updated
     * @throws ApiError
     */
    public static publicUpdateCalendar({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: PublicCalendarUpdateDto,
    }): CancelablePromise<PublicCalendarGetDto> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/v1/calendars/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

}
