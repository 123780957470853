/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type GeneratingTransformationStatusDto = {
    lastRunStatus?: GeneratingTransformationStatusDto.lastRunStatus;
    type?: GeneratingTransformationStatusDto.type;
};

export namespace GeneratingTransformationStatusDto {

    export enum lastRunStatus {
        SUCCESS = 'SUCCESS',
        ERROR = 'ERROR',
        SKIPPED = 'SKIPPED',
        PARTIAL_SUCCESS = 'PARTIAL_SUCCESS',
        NOT_TARGETED = 'NOT_TARGETED',
        UNKNOWN = 'UNKNOWN',
    }

    export enum type {
        FIVETRAN_CONNECTOR = 'FIVETRAN_CONNECTOR',
        DBT_MODEL = 'DBT_MODEL',
        DBT_SEED = 'DBT_SEED',
    }


}
